import {Facebook, Instagram, Linkedin, Location, Phone, Profile, Triangle, Twitter} from '../components/svg'
import {graphql, useStaticQuery} from 'gatsby'

import {Form} from '../components/netlify'
import {Header} from '../components/header'
import {Image} from '../components/image'
import {Link} from '../components/link'
import React from 'react'
import {t} from 'frenchkiss'

const ElementsPage = () => (
	<>
		<header>
			<h1>Title H1</h1>
			<h2>Title H2</h2>
			<h3>Title H3</h3>
			<h4>Title H4</h4>
			<h5>Title H5</h5>
			<h6>Title H6</h6>
		</header>
		<div>
			<a href='/#'>Link outside P</a>
			<p>
				<a href='/#'>Link inside P</a>
				<i>Lorem ipsum</i>
				<br />
				<strong>Integer mollis egestas</strong> nam maximus erat id euismod egestas. Pellentesque sapien ac quam.
				<b>Lorem ipsum</b> dolor sit nullam.
			</p>
			<p>
				Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur consectetur inventore beatae voluptatem fugit
				ratione id sunt eligendi quis assumenda doloremque soluta, eos obcaecati consequatur? Hic natus illum vitae ea.
			</p>

			<h3>Title H3</h3>
			<p>
				Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur consectetur inventore beatae voluptatem fugit
				ratione id sunt eligendi quis assumenda doloremque soluta, eos obcaecati consequatur? Hic natus illum vitae ea.
			</p>

			<hr />
			<blockquote>
				Fringilla nisl. Donec accumsan interdum nisi, quis tincidunt felis sagittis eget tempus euismod. Vestibulum ante ipsum
				primis in faucibus vestibulum. Blandit adipiscing eu felis iaculis volutpat ac adipiscing accumsan faucibus. Vestibulum
				ante ipsum primis in faucibus lorem ipsum dolor sit amet nullam adipiscing eu felis.
			</blockquote>
			<pre>
				<code>
					i = 0; while (!deck.isInOrder()){' '}
					{`{
  print 'Iteration ' + i;
  deck.shuffle();
  i++;
}`}
					print 'It took ' + i + ' iterations to sort the deck.';
				</code>
			</pre>
			<hr />
			<ul>
				<li>Dolor pulvinar etiam.</li>
				<li>Sagittis adipiscing.</li>
				<li>Felis enim feugiat.</li>
			</ul>
			<ol>
				<li>Dolor pulvinar etiam.</li>
				<li>Etiam vel felis viverra.</li>
				<li>Felis enim feugiat.</li>
				<li>Dolor pulvinar etiam.</li>
				<li>Etiam vel felis lorem.</li>
				<li>Felis enim et feugiat.</li>
			</ol>
		</div>
	</>
)
export default ElementsPage
